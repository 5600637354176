.exp{
    padding: 1em;
    background-color: #696969;
    color: white;
    border-radius: 1em;
    margin: 0em;
    display: inline-block;
    box-shadow: 0 2px 2px 2px rgba(74, 70, 70, 0.23);
   
}
.list{
    padding: 2em;
    list-style-type: none;
    display: block;
    color: white;
    text-align: left;
    list-style:circle;
    padding: 0.2em;
}
.bot{
    padding: 0.6em;
    border-radius: 1em;
    background-color: #0053b2;
    color: white;
}
.expul{
    list-style-type: none;
    
    display: block;
    color: white;
    text-align: left;
}
h4{
    color: white;
    font-size: 2em;
    font-weight: 250;
    font-family: 'Nanum Gothic', sans-serif;
    

}

.expul li{
    margin: 0.5em;
    border-radius: 1em;
    border: white;
    border: 1px solid;
    display: grid;
    padding: 1em;
    
}
.expbttn{
    font-family: 'Nanum Gothic', sans-serif;
    background-color: #0053b2;
    color: white;
    border: 2px solid #0053b2;
    border-radius: 1em;
    padding: 0.6em;
    margin: 0.6em;
    display: inline-block;
    box-shadow: 0 2px 2px 2px rgba(81, 79, 79, 0.23);
}
.expbttn:hover{
    background-color: #0053b2;
    color: #383838;
   
    
    display: inline-block;
    box-shadow: none;
}
.modal{
    text-align: left;
    color: rgb(255, 255, 255);
}
.modaltext{
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-weight: 250;
    font-family: 'Nanum Gothic', sans-serif;
    display: block;
    text-align: left;
}
.item-title{
    color: aliceblue;
}
.item-sub-title{
    color: aliceblue;
    font-weight: 1em;
    font-family: 'Nanum Gothic', sans-serif;
    
}
.item-list-item-title{
    font-size: 1.4em;
    text-align: center;
    font: weight 1em;
    font-family: 'Nanum Gothic', sans-serif;
    color:  #ffffff;
}
.list-text{
    font-size: 1em;
    text-align: left;
    font: weight 1em;
    font-family: 'Nanum Gothic', sans-serif;
    color:  #000000;;
}
.button{
    font-family: 'Nanum Gothic', sans-serif;
    background-color: #0053b2;
    color: white;
    border: 2px solid #0053b2;
    border-radius: 1em;
    padding: 0.6em;
    margin: 0.6em;
    display: inline-block;
    box-shadow: 0 2px 2px 2px rgba(81, 79, 79, 0.23);
    text-align: center;

}
.inner-list{
    list-style-type: none;
    display: block;
    color: white;
    text-align: left;
    list-style:circle;
    

}
.inner-list-item{
    
    font-family: 'Nanum Gothic', sans-serif;
    display: grid;
    padding: 0.2em;

}
.ol-li{
    padding: 2em;
    text-align: center;
}
.sub{
    font-size: 2em;
    text-align: center;
    font-weight: 250;
    font-family: 'Nanum Gothic', sans-serif;
    display: block;
    
    color: #ffffff;
}
.modal-content{
    padding: 1.2em;
}
ul{
    list-style-type: none;
    display: block;
    color: white;
    text-align: left;
    list-style:circle;
    padding: 0.2em;
    list-style-type: circle;
}
.expli{
    margin: 0.4em;
    border-radius: 1em;
    border: white;
    border: 1px solid;
    display: grid;
    padding: 1em;
    margin: 0.2em;
    
}

.body h1{

    color: white;
    font-size: 4em;
}
.body{
    padding: 0;
    margin: 0;
}

.video{
    padding: 0;
    margin: 1em;
    
    
    z-index: -1;
    align-items: center;
    display: block;
    float: centre;
}
.multi-media h4{
    display: inline-block;
    padding: 0;
    margin: 0;
    z-index: -1;
    align-items: center;
   

}
.modal-content .title{
    font-size: 50px;
    color: #ffffff;
}

.modal-content .item-sub-title h2{
    color: white;
    padding: 0;
    font-size: 30px;
}

.list-items-projects{
    padding: 2em;
    color: white;
    text-align: left;
    list-style:circle;
}

.classification{
    padding: 2em;
    color: white;
    text-align: left;
    list-style:circle;
}
.classification ul{
    list-style-type: none;
    display: block;
    color: white;
    text-align: left;
    list-style:circle;
    padding: 0.2em;
    list-style-type: circle;
}
.classification h1{
    color: white;
    font-size: 4em;
}
.classification h3{
    color: rgb(7, 7, 7);
    font-size: 2em;
    font-weight: 250;
    margin-top: 1em;
    
}