h1{
    color: #0053b2;
    font-size: 19;
    text-align: center;
}
.abtCon{
    padding: 1%;
}
.abtCta{
    font-size: small;
    margin: 1em;
    padding: 1em;
    border-radius: 0.2em;
    font-weight: 100;
    font-family: 'Nanum Gothic', sans-serif;
    transition: all 0.3s ease-in-out;
    display: inline-block;
}
.abtCta:nth-child(odd){
    background-color: #383838;
    border-color: rgb(0, 0, 0);
    border: 2px solid rgb(0, 0, 0);
    padding: 0.5em;
    margin: '0.5em';
    color: rgb(255, 255, 255);
    margin: 1em;
}
.abtCta:nth-child(odd):hover{
    border: 2px solid rgb(255, 255, 255);
    padding: 1.2em;
    background-color: #ffffff;
    color:rgb(0, 0, 0);
    margin: 1em;
}
.abtCta:nth-child(even){
    border: 2px solid rgb(255, 255, 255);
    padding: 0.5em;
    border: 2px solid rgb(0, 0, 0);
    background-color: #ffffff;
    color:rgb(0, 0, 0);
    margin: 1em;
}
.abtCta:nth-child(even):hover{
    background-color: #383838;
    padding: 1.2em;
    margin: '0.5em';
    color: white;
    margin: 1em;
}

.abtcontlist{
    padding: 2em;
    border-radius: 0.75em;
}
.contCan{
   margin: 2em;
   padding: 0.5em;
   background-color: #383838;
   border-radius: 0.5em;
   color: #0053b2;
   font-family: 'Nanum Gothic', sans-serif;
   text-align: left;
   box-shadow: 1px 4px 3px 3px rgba(74, 70, 70, 0.23);
   
}
.abth{
    color: rgb(0, 0, 0);
    font-size: 1.5em;
    font-weight: 500;
    font-family: 'Nanum Gothic', sans-serif;
    display: block;
   
}
.abtht{
    color: rgb(255, 255, 255);
    font-size: 1.5em;
    font-weight: 500;
    font-family: 'Nanum Gothic', sans-serif;
    display: block;
}
.abth.h1.h2{
    padding: 2em;
    color: #0053b2;
}
.OuterList{
    display: block;
}
.pos{
    color: #0053b2;
}
.oElem{
    padding: 1em;
}
.headimg{
    width: 20%;
    border-radius: 0.5em;
    box-shadow: 1px 4px 3px 3px rgba(74, 70, 70, 0.23);
    display: block;
}
.imgWrapper{
    display: block;
    margin: 1em;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: 1px 4px 3px 3px rgba(74, 70, 70, 0.23);
}


.iElem{
    transition: all 0.5s ease-in-out;
   background-color: white;
    padding: 0.6em;
    border-radius: 0.75em;
    margin: 1em;
    color: rgb(0, 0, 0);
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    box-shadow: 0px 2px 2px 6px rgba(74, 70, 70, 0.23);

}
.iElem:hover images{
    opacity: 1;
}
.innerContent{
    float: top;
    display: inline-block;
    margin: 1em;
    padding: 1em;
}
p{
    opacity: 100;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: rgb(0, 0, 0);
}


.iElem:hover img{
    opacity: 1;
    width: 30%;
    margin-right: 1em;
}
.iElem:hover p{
    opacity: 1;
    display: inline-block;
}
.iElem:hover + .images{
    display: block;
    transition: all 0.3s ease-in-out;
}
.iElem:hover{
    padding: 1em;
    border-radius: 0.75em;
    margin: 2em;
    color: white;
    font-family: 'Nanum Gothic', sans-serif;
    text-align: left;
    box-shadow: 0px 4px 4px 12px rgba(74, 70, 70, 0.23);

}


.innerlist{
    margin-top: 8em;
    padding: 4em;
    border-radius: 0.75em;
    margin-left:    1em;
    color: white;
    margin: 0.5em;
    font-family: 'Nanum Gothic', sans-serif;
}

.cont{
    display: block;
    margin: 2em;
    padding: 4em;
}
.innerContent{
    display: inline-block;
    
    text-align: left;
}
.head{
  
    border-radius: 4px;
    padding: 5px;
    width: 300px;
}
.imageHolder{
    display: inline-block;
    float: centre;
    margin: 1em;
    padding: 1em;
    border-radius: 0.5em;
   
}