.footer{
    padding: 2em;
    margin-top: 4em;
    color: white;
    background-color: #383838;
    font-family: 'Nanum Gothic', sans-serif;
    box-shadow: 0px 2px 2px 6px rgba(74, 70, 70, 0.23);
}
.footer h4{
    
    color: white;
    font-family: 'Nanum Gothic', sans-serif;
   
}