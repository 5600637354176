
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
.container {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top:2em ;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
  }

:root{
    --color-bg: black;
    --color-text: rgb(8, 5, 5);
    --color-primary: #000000;
    --color-secondary: #ffffff;
    --color-tertiary: #75c1ff;
    --color-quaternary: #63d0ff;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    display: none;
}
body{
    background: white;
    color: rgb(0, 0, 0);
    line-height: 1.7;
}


/*================= general styles =================*/

.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}


h1,h2,h3,h4,h5{
    color: var(--color-text);
    font-weight: 500;
    font-family: 'Nanum Gothic', sans-serif;
    display: block;
   
}
.a{
    color: var(--color-text);
    background-color: var(--color-tertiary);
    padding-left: 1em;
    padding-right: 1em;
    margin: 0.5em;
    border-radius: 0.75em;
    font-weight: 500;
    font-family: 'Nanum Gothic', sans-serif;
    display: inline-block;
    transition: all 0.3s ease-in-out;
   
}
.a:hover{
    color: var(--color-primary);
    background-color: var(--color-text);
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

/*================= header =================*/
.header__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}
.text-light{
    color: var(--color-quaternary);
}